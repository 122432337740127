import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../../assets/img/logoFull.png'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../../variables'

import NavbarSA from '../../../components/navbar-sa'

const ViewBillUser = (props) => {
  const notyf = new Notyf()
  const [bill, setBill] = useState({})
  const [consumer, setConsumer] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('New Bill')
  const [selectedFile, setSelectedFile] = useState({})
  const consumerID = useParams().consumer
  const billID = useParams().bill

  const handleInpChange = (event) => {
    if (event.target.name === 'billName') {
      setBill({
        ...bill,
        [event.target.name]: event.target.value,
        managerEmail: `${event.target.value
          .toLowerCase()
          .replace(/ /g, '_')}.admin@rean.co.in`,
      })
    } else {
      setBill({
        ...bill,
        [event.target.name]: event.target.value,
        consumerID: consumerID,
      })
    }
  }

  const getBill = async () => {
    await Axios.get(server + 'user/get-bill/' + billID, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          console.log('edit User', data)
          setBill(data.bill)
          setConsumer(data.user)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
    getBill()
  }, [])

  return (
    <>
      {bill && (
        <div className='page new-user'>
          <NavbarSA />
          <div className='page-title'>Bill ({bill.billID})</div>

          {/* form */}

          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer ID'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={bill.consumerID}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                />
              </div>
              {consumer ? (
                consumer.consumerType == 'Residential Consumer' ||
                consumer.consumerType ==
                  'Residential Cum Commercial Consumer' ? (
                  <>
                    <div class='mb-3'>
                      <TextField
                        id='outlined-basic'
                        label='Consumer Name'
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        value={consumer.consumerName}
                        className='input col-12'
                        size='small'
                        onChange={handleInpChange}
                        disabled
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {consumer ? (
                consumer.consumerType == 'Commercial Consumer' ||
                consumer.consumerType ==
                  'Residential Cum Commercial Consumer' ? (
                  <>
                    <div class='mb-3'>
                      <TextField
                        id='outlined-basic'
                        label='Consumer Name'
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        value={consumer.bussinessName}
                        className='input col-12'
                        size='small'
                        onChange={handleInpChange}
                        disabled
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer Type'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer ? consumer.consumerType : ''}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer ? consumer.meterNumber : ''}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer ? consumer.mobileNumber : ''}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Due Date'
                  variant='outlined'
                  // type='date'
                  InputLabelProps={{ shrink: true }}
                  value={bill.dueDate ? bill.dueDate.split('T')[0] : ''}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Reading Date'
                  variant='outlined'
                  // type='date'
                  InputLabelProps={{ shrink: true }}
                  value={bill.readingDate ? bill.readingDate.split('T')[0] : ''}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Total Consumption'
                  variant='outlined'
                  type='number'
                  value={bill.totalConsumption}
                  InputLabelProps={{ shrink: true }}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                  name='currentMeterReading'
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Previous Meter Reading'
                  variant='outlined'
                  type='number'
                  value={bill.lastMeterReading}
                  InputLabelProps={{ shrink: true }}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                  name='currentMeterReading'
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Current Meter Reading'
                  variant='outlined'
                  type='number'
                  value={bill.currentMeterReading}
                  InputLabelProps={{ shrink: true }}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                  name='currentMeterReading'
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Penalty'
                  variant='outlined'
                  value={bill.penalty}
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                  name='penalty'
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Fixed Charges'
                  variant='outlined'
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  disabled
                  value={bill.fixedCharges}
                  name='fixedCharges'
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Other Charges'
                  variant='outlined'
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  className='input col-12'
                  size='small'
                  value={bill.otherCharges}
                  onChange={handleInpChange}
                  disabled
                  name='otherCharges'
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Total Amount Payable'
                  variant='outlined'
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  value={bill.totalAmountPayable}
                  disabled
                  name='totalAmountPayable'
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

export default ViewBillUser
