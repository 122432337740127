import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../../variables'
import { Checkbox, FormControlLabel } from '@mui/material'
import NavbarSA from '../../../components/navbar-sa'

const ViewConsumerUser = (props) => {
  const notyf = new Notyf()
  const consumerID = useParams().consumer
  const [consumer, setConsumer] = useState({})
  const navigate = useNavigate()
  const [consumerType, setConsumerType] = useState('None')
  const schemeID = useParams().id

  const getConsumer = async () => {
    await Axios.get(server + 'user/get-consumer/' + consumerID, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          console.log('edit User', data)
          setConsumerType(data.consumer.consumerType)
          setConsumer(data.consumer)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
    getConsumer()
  }, [])

  return (
    <div className='page new-user'>
      <NavbarSA />

      {/* form */}
      {consumerType == 'Commercial Consumer' ? (
        <>
          <div className='page-title'>Commercial Consumer </div>
          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Name'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  value={consumer.bussinessName}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Nature'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  value={consumer.bussinessNature}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Owner Status'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  value={consumer.ownershipStatus}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>

              <FormControlLabel
                control={
                  <Checkbox value={consumer.isEducationInstitute} disabled />
                }
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isEducationInstitute: e.target.checked,
                  })
                }}
                label='Is this a edcational institute'
              />
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Identity Type'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  value={consumer.identityType}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  variant='outlined'
                  className='input col-12'
                  InputLabelProps={{ shrink: true }}
                  size='small'
                  value={consumer.identityNumber}
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  variant='outlined'
                  className='input col-12'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.phoneNumber}
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.address}
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  value={consumer.latitude}
                  variant='outlined'
                  type='number'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  type='number'
                  label='Longitude'
                  name='longitude'
                  value={consumer.longitude}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.mobileNumber}
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.email}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.meterNumber}
                  label='Meter Number'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.wardNumber}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.zoneNumber}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  value={
                    consumer.installationDate
                      ? new Date(consumer.installationDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
            </div>
          </form>
        </>
      ) : consumerType == 'Residential Consumer' ? (
        <>
          <div className='page-title'>Residential Consumer </div>

          {/* form */}

          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.consumerName}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Father or Husband Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.fatherHusbandName}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Locality Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.localityName}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Building Type'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.buildingType}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Property Type'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.propertyType}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Identity Type'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.identityType}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.identityNumber}
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Social Status'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.socialStatus}
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <FormControlLabel
                control={<Checkbox checked={consumer.isHandicape} disabled />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isHandicape: e.target.checked,
                  })
                }}
                label='Is Handicaped'
              />
              <FormControlLabel
                control={<Checkbox checked={consumer.isBPL} disabled />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isBPL: e.target.checked,
                  })
                }}
                label='Is Bpl'
              />

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.address}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  value={consumer.latitude}
                  variant='outlined'
                  type='number'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  type='number'
                  label='Longitude'
                  name='longitude'
                  value={consumer.longitude}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.mobileNumber}
                  variant='outlined'
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.email}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  className='input col-12'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.meterNumber}
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.wardNumber}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.zoneNumber}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  value={
                    consumer.installationDate
                      ? new Date(consumer.installationDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
            </div>
          </form>
        </>
      ) : consumerType == 'Residential Cum Commercial Consumer' ? (
        <>
          <div className='page-title'>Residential Cum Commercial Consumer</div>

          {/* form */}

          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Name'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.bussinessName}
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Nature'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.bussinessNature}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ownership Status'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.ownershipStatus}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox checked={consumer.isEducationInstitute} disabled />
                }
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isEducationInstitute: e.target.checked,
                  })
                }}
                label='Is this a edcational institute'
              />
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  variant='outlined'
                  className='input col-12'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.identityNumber}
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.phoneNumber}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Father or Husband Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.fatherHusbandName}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Locality Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.localityName}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Builiding Type'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.buildingType}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Property Type'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.propertyType}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Identity Type'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.identityType}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Social Status'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.socialStatus}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <FormControlLabel
                control={<Checkbox checked={consumer.isHandicape} disabled />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isHandicape: e.target.checked,
                  })
                }}
                label='Is Handicaped'
              />
              <FormControlLabel
                control={<Checkbox checked={consumer.isBPL} disabled />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isBPL: e.target.checked,
                  })
                }}
                label='Is Bpl'
              />
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.address}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  value={consumer.latitude}
                  variant='outlined'
                  type='number'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  type='number'
                  label='Longitude'
                  name='longitude'
                  value={consumer.longitude}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.mobileNumber}
                  variant='outlined'
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.email}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.meterNumber}
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.wardNumber}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.zoneNumber}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  value={
                    consumer.installationDate
                      ? new Date(consumer.installationDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  disabled
                />
              </div>
            </div>
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ViewConsumerUser
