import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../../assets/img/logoFull.png'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../../variables'
import Select from 'react-select'
import NavbarSA from '../../../components/navbar-sa'

const NewScheme = (props) => {
  const notyf = new Notyf()
  const [scheme, setScheme] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('Register Scheme')

  const handleInpChange = (event) => {
    if (event.target.name === 'schemeName') {
      setScheme({
        ...scheme,
        [event.target.name]: event.target.value,
        managerEmail: `${event.target.value
          .toLowerCase()
          .replace(/ /g, '_')}.admin@rean.co.in`,
      })
    } else {
      setScheme({
        ...scheme,
        [event.target.name]: event.target.value,
      })
    }
  }

  const addUser = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    if (
      !scheme.schemeName ||
      !scheme.managerEmail ||
      !scheme.managerName ||
      !scheme.managerPass
    ) {
      notyf.error({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Please fill all the fields!',
        duration: 3000,
        dismissible: true,
      })
      setIsLoading(false)
    } else {
      await Axios.post(server + 'admin/new-scheme', scheme, {
        headers: { authorization: localStorage.getItem('token') },
      })
        .then(({ data }) => {
          setIsLoading(false)

          if (data.status == 410) {
            navigate('/')
          } else if (data.status === 200) {
            // redirect to dashboard
            notyf.success({
              position: {
                x: 'right',
                y: 'top',
              },
              message: 'Scheme registeration successful!',
              duration: 3000,
              dismissible: true,
            })
            navigate('/admin/dashboard')
          } else {
            notyf.error({
              position: {
                x: 'right',
                y: 'top',
              },
              message: data.message,
              duration: 3000,
              dismissible: true,
            })
          }
          // console.log({email,password,data})
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'Internal Server Error!',
            duration: 3000,
            dismissible: true,
          })
        })
    }
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
  }, [])

  return (
    <div className='page new-user'>
      <NavbarSA />
      <div className='page-title'>Scheme Registration</div>

      {/* form */}

      <form className='form col-md-4 offset-md-4 col-12'>
        <div className='mb-3'>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Scheme Name'
              variant='outlined'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='schemeName'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label="Scheme Manager's Email"
              variant='outlined'
              className='input col-12'
              size='small'
              // onChange={handleInpChange}
              required
              value={scheme.managerEmail}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label="Manager's Name"
              variant='outlined'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='managerName'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label="Manager's Password"
              variant='outlined'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              type='password'
              name='managerPass'
            />
          </div>

          <button
            className='w-100 btn btn-md btn-primary'
            disabled={isLoading}
            onClick={addUser}
          >
            {isLoading ? (
              <span
                className='spinner-border spinner-border-sm me-2'
                role='status'
                aria-hidden='true'
              />
            ) : (
              btnText
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default NewScheme
