import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import DataTable from 'react-data-table-component'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import { ExportToCsv } from 'export-to-csv'
import server from '../../../variables'
import NavbarSA from '../../../components/navbar-sa'
import { Tooltip } from '@mui/material'
import DataCard from '../../../components/dataCard'
import ChecklistIcon from '@mui/icons-material/Checklist'

const ConsumerDashboardUser = (props) => {
  const notyf = new Notyf()
  const navigate = useNavigate()
  const schemeID = useParams().id
  const [consumers, setConsumer] = useState([])
  const [userData, setUserData] = useState({})
  const [residenitalList, setResidenitalList] = useState([])
  const [commercialList, setCommercialList] = useState([])
  const [residentialCumCommercialList, setResidentialCumCommercialList] =
    useState([])
  const [baseUser, setBaseUser] = useState([])
  const [selectedRowsCSV, setSelectedRowsCSV] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [consumerType, setConsumerType] = useState('None')

  const [search, setSearch] = useState('')

  useEffect(() => {
    document.title = 'Dashboard | RBS'
    fetchConsumer()
  }, [])

  const fetchConsumer = async () => {
    await Axios.get(server + 'user/get-all-consumer/', {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        console.log(data)
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          setConsumer(data.consumer)
          setUserData(data.user)
          let resList = []
          let comList = []
          let resCOmList = []
          data.consumer.map((item) => {
            if (item.consumerType == 'Residential Consumer') {
              resList.push(item)
            } else if (
              item.consumerType == 'Residential Cum Commercial Consumer'
            ) {
              resCOmList.push(item)
            } else if (item.consumerType == 'Commercial Consumer') {
              comList.push(item)
            }
          })
          setResidenitalList(resList)
          setCommercialList(comList)
          setResidentialCumCommercialList(resCOmList)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const columns = [
    {
      name: 'Consumer ID',
      selector: (row) => row.consumerID,
      sortable: true,
    },
    {
      name: 'Meter Number',
      selector: (row) => row.meterNumber,
      sortable: true,
    },
    {
      name: 'Consumer Type',
      selector: (row) => row.consumerType,
      sortable: true,
    },

    {
      name: 'Mobile Number',
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <div
            className='btn btn-outline-primary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/view`)
            }}
          >
            <i className='bi bi-eye-fill' style={{ cursor: 'pointer' }}></i>
          </div>
          {userData.role == 'manager' && (
            <div
              className='btn btn-outline-warning btn-sm mx-2'
              onClick={() => {
                navigate(`/dashboard/consumers/${row.consumerID}/edit`)
              }}
            >
              <i
                className='bi bi-pencil-square'
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
          )}
          <div
            className='btn btn-outline-secondary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/bill`)
            }}
          >
            <i
              className='bi bi-receipt'
              style={{ cursor: 'pointer' }}
              onClick={
                () => {}
                // window.open(row.otherDetails.onedrive.procFolder.procFolderLink)
              }
            ></i>
          </div>
        </div>
      ),
      center: true,
    },
  ]

  const residentilColumns = [
    {
      name: 'Consumer ID',
      selector: (row) => row.consumerID,
      sortable: true,
    },
    {
      name: 'Consumer Name',
      selector: (row) => row.consumerName,
      sortable: true,
    },

    {
      name: 'Mobile Number',
      selector: (row) => row.mobileNumber,
      sortable: true,
    },

    {
      name: 'Meter Number',
      selector: (row) => row.meterNumber,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <div
            className='btn btn-outline-primary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/view`)
            }}
          >
            <i className='bi bi-eye-fill' style={{ cursor: 'pointer' }}></i>
          </div>
          {userData.role == 'manager' && (
            <div
              className='btn btn-outline-warning btn-sm mx-2'
              onClick={() => {
                navigate(`/dashboard/consumers/${row.consumerID}/edit`)
              }}
            >
              <i
                className='bi bi-pencil-square'
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
          )}
          <div
            className='btn btn-outline-secondary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/bill`)
            }}
          >
            <i
              className='bi bi-receipt'
              style={{ cursor: 'pointer' }}
              onClick={
                () => {}
                // window.open(row.otherDetails.onedrive.procFolder.procFolderLink)
              }
            ></i>
          </div>
        </div>
      ),
      center: true,
    },
  ]

  const resCumComColumns = [
    {
      name: 'Consumer ID',
      selector: (row) => row.consumerID,
      sortable: true,
    },
    {
      name: 'Consumer Name',
      selector: (row) => row.consumerName,
      sortable: true,
    },

    {
      name: 'Mobile Number',
      selector: (row) => row.mobileNumber,
      sortable: true,
    },

    {
      name: 'Meter Number',
      selector: (row) => row.meterNumber,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <div
            className='btn btn-outline-primary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/view`)
            }}
          >
            <i className='bi bi-eye-fill' style={{ cursor: 'pointer' }}></i>
          </div>
          {userData.role == 'manager' && (
            <div
              className='btn btn-outline-warning btn-sm mx-2'
              onClick={() => {
                navigate(`/dashboard/consumers/${row.consumerID}/edit`)
              }}
            >
              <i
                className='bi bi-pencil-square'
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
          )}
          <div
            className='btn btn-outline-secondary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/bill`)
            }}
          >
            <i
              className='bi bi-receipt'
              style={{ cursor: 'pointer' }}
              onClick={
                () => {}
                // window.open(row.otherDetails.onedrive.procFolder.procFolderLink)
              }
            ></i>
          </div>
        </div>
      ),
      center: true,
    },
  ]

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRowsCSV(selectedRows)
  }

  const exportToCSV = () => {
    if (selectedRowsCSV.length === 0) {
      const data = residentialCumCommercialList.map((row) => {
        return {
          'User ID': row.consumerID,
          'Consumer Name': row.consumerName,
          Email: row.email,
          'Mobile Number': row.mobileNumber,
          Address: row.address,
          'Meter Number': row.meterNumber,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Consumer List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Consumer List',
        headers: [
          'Consumer ID',
          'Consumer Name',
          'Email',
          'Mobile Number',
          'Address',
          'Meter Number',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
    } else {
      const data = selectedRowsCSV.map((row) => {
        return {
          'User ID': row.consumerID,
          'Consumer Name': row.consumerName,
          Email: row.email,
          'Mobile Number': row.mobileNumber,
          Address: row.address,
          'Meter Number': row.meterNumber,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Consumer List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Consumer List',
        headers: [
          'Consumer ID',
          'Consumer Name',
          'Email',
          'Mobile Number',
          'Address',
          'Meter Number',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
      setToggleCleared(!toggleCleared)
      setSelectedRowsCSV([])
    }
  }

  const commercialColumns = [
    {
      name: 'Consumer ID',
      selector: (row) => row.consumerID,
      sortable: true,
    },
    {
      name: 'Bussiness Name',
      selector: (row) => row.bussinessName,
      sortable: true,
    },

    {
      name: 'Mobile Number',
      selector: (row) => row.mobileNumber,
      sortable: true,
    },

    {
      name: 'Meter Number',
      selector: (row) => row.meterNumber,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <div
            className='btn btn-outline-primary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/view`)
            }}
          >
            <i className='bi bi-eye-fill' style={{ cursor: 'pointer' }}></i>
          </div>
          {userData.role == 'manager' && (
            <div
              className='btn btn-outline-warning btn-sm mx-2'
              onClick={() => {
                navigate(`/dashboard/consumers/${row.consumerID}/edit`)
              }}
            >
              <i
                className='bi bi-pencil-square'
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
          )}
          <div
            className='btn btn-outline-secondary btn-sm mx-2'
            onClick={() => {
              navigate(`/dashboard/consumers/${row.consumerID}/bill`)
            }}
          >
            <i
              className='bi bi-receipt'
              style={{ cursor: 'pointer' }}
              onClick={
                () => {}
                // window.open(row.otherDetails.onedrive.procFolder.procFolderLink)
              }
            ></i>
          </div>
        </div>
      ),
      center: true,
    },
  ]
  const exportToCSV2 = () => {
    if (selectedRowsCSV.length === 0) {
      const data = consumers.map((row) => {
        return {
          'User ID': row.consumerID,
          Email: row.email,
          'Mobile Number': row.mobileNumber,
          Address: row.address,
          'Meter Number': row.meterNumber,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Consumer List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Consumer List',
        headers: [
          'Consumer ID',
          'Email',
          'Mobile Number',
          'Address',
          'Meter Number',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
    } else {
      const data = selectedRowsCSV.map((row) => {
        return {
          'User ID': row.consumerID,
          Email: row.email,
          'Mobile Number': row.mobileNumber,
          Address: row.address,
          'Meter Number': row.meterNumber,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Consumer List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Consumer List',
        headers: [
          'Consumer ID',
          'Email',
          'Mobile Number',
          'Address',
          'Meter Number',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
      setToggleCleared(!toggleCleared)
      setSelectedRowsCSV([])
    }
  }
  const exportToCSV1 = () => {
    if (selectedRowsCSV.length === 0) {
      const data = residentialCumCommercialList.map((row) => {
        return {
          'User ID': row.consumerID,
          'Bussiness Name': row.bussinessName,
          Email: row.email,
          'Mobile Number': row.mobileNumber,
          Address: row.address,
          'Meter Number': row.meterNumber,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Consumer List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Consumer List',
        headers: [
          'Consumer ID',
          'Bussiness Name',
          'Email',
          'Mobile Number',
          'Address',
          'Meter Number',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
    } else {
      const data = selectedRowsCSV.map((row) => {
        return {
          'User ID': row.consumerID,
          'Bussiness Name': row.consumerName,
          Email: row.email,
          'Mobile Number': row.mobileNumber,
          Address: row.address,
          'Meter Number': row.meterNumber,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Consumer List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Consumer List',
        headers: [
          'Consumer ID',
          'Bussiness Name',
          'Email',
          'Mobile Number',
          'Address',
          'Meter Number',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
      setToggleCleared(!toggleCleared)
      setSelectedRowsCSV([])
    }
  }

  return (
    <div className='page sa-dashboard'>
      <NavbarSA />
      <div className='dashboard-cards row my-5'>
        <div
          className='col-lg-3 col-md-6'
          onClick={() => {
            setConsumerType('None')
          }}
        >
          <DataCard
            heading='Total Consumer'
            text=''
            color='#5559f7'
            // location='/project-management/procurement'
            image={<ChecklistIcon style={{ fontSize: 30 }} />}
          />
        </div>
        <div
          className='col-lg-3 col-md-6 '
          onClick={() => {
            setConsumerType('Residential Consumer')
          }}
        >
          <DataCard
            heading='Residential Consumer'
            text=''
            color='#5559f7'
            // location={`/dashboard/consumers`}
            image={<ChecklistIcon style={{ fontSize: 30 }} />}
          />
        </div>
        <div
          className='col-lg-3 col-md-6'
          onClick={() => {
            setConsumerType('Commercial Consumer')
          }}
        >
          <DataCard
            heading='Commercial Consumer'
            text=''
            color='#5559f7'
            // location='/project-management/procurement'
            image={<ChecklistIcon style={{ fontSize: 30 }} />}
          />
        </div>
        <div
          className='col-lg-3 col-md-6'
          onClick={() => {
            setConsumerType('Residential Cum Commercial Consumer')
          }}
        >
          <DataCard
            heading='Residential Cum Commercial Consumer'
            text=''
            color='#5559f7'
            // location='/project-management/procurement'
            image={<ChecklistIcon style={{ fontSize: 30 }} />}
          />
        </div>
      </div>
      {/*  */}
      {consumerType == 'None' && (
        <>
          <DataTable
            title='Consumer List'
            columns={columns}
            data={consumers}
            // pagination
            fixedHeader
            fixedHeaderScrollHeight='600px'
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            striped
            actions={
              <>
                {consumers.length > 0 && (
                  <div className='btn btn-success' onClick={exportToCSV2}>
                    <i className='bi bi-download'></i>
                  </div>
                )}

                {userData.role == 'manager' && (
                  <Tooltip title='Add Commercial Consumer'>
                    <Link
                      className='btn btn-primary'
                      // style={{ cursor: 'default' }}
                      to={`/dashboard/consumers/add-consumer`}
                    >
                      <i className='bi bi-plus-lg fa-xl'></i>
                    </Link>
                  </Tooltip>
                )}
              </>
            }
            subHeader
            pagination
            subHeaderComponent={
              <TextField
                id='outlined-basic'
                label='Search by Name'
                variant='outlined'
                className='input w-100'
                size='small'
                onChange={(e) => setSearch(e.target.value)}

                // onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign='left'
            onSelectedRowsChange={handleSelectedRows}
            clearSelectedRows={toggleCleared}
            noContextMenu={true}
          />
        </>
      )}
      {consumerType == 'Residential Consumer' && (
        <>
          <DataTable
            title='Residential Consumer List'
            columns={residentilColumns}
            data={residenitalList}
            // pagination
            fixedHeader
            fixedHeaderScrollHeight='600px'
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            striped
            actions={
              <>
                {residenitalList.length > 0 && (
                  <div className='btn btn-success' onClick={exportToCSV}>
                    <i className='bi bi-download'></i>
                  </div>
                )}

                {userData.role == 'manager' && (
                  <Tooltip title='Add Commercial Consumer'>
                    <Link
                      className='btn btn-primary'
                      // style={{ cursor: 'default' }}
                      to={`/dashboard/consumers/add-consumer`}
                    >
                      <i className='bi bi-plus-lg fa-xl'></i>
                    </Link>
                  </Tooltip>
                )}
              </>
            }
            subHeader
            pagination
            subHeaderComponent={
              <TextField
                id='outlined-basic'
                label='Search by Name'
                variant='outlined'
                className='input w-100'
                size='small'
                onChange={(e) => setSearch(e.target.value)}

                // onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign='left'
            onSelectedRowsChange={handleSelectedRows}
            clearSelectedRows={toggleCleared}
            noContextMenu={true}
          />
        </>
      )}
      {consumerType == 'Commercial Consumer' && (
        <>
          <DataTable
            title='Commercial Consumer List'
            columns={commercialColumns}
            data={commercialList}
            // pagination
            fixedHeader
            fixedHeaderScrollHeight='600px'
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            striped
            actions={
              <>
                {commercialList.length > 0 && (
                  <div className='btn btn-success' onClick={exportToCSV1}>
                    <i className='bi bi-download'></i>
                  </div>
                )}

                {userData.role == 'manager' && (
                  <Tooltip title='Add Commercial Consumer'>
                    <Link
                      className='btn btn-primary'
                      // style={{ cursor: 'default' }}
                      to={`/dashboard/consumers/add-consumer`}
                    >
                      <i className='bi bi-plus-lg fa-xl'></i>
                    </Link>
                  </Tooltip>
                )}
              </>
            }
            subHeader
            pagination
            subHeaderComponent={
              <TextField
                id='outlined-basic'
                label='Search by Name'
                variant='outlined'
                className='input w-100'
                size='small'
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign='left'
            onSelectedRowsChange={handleSelectedRows}
            clearSelectedRows={toggleCleared}
            noContextMenu={true}
          />
        </>
      )}
      {consumerType == 'Residential Cum Commercial Consumer' && (
        <>
          <DataTable
            title='Residential Cum Commercial Consumer List'
            columns={resCumComColumns}
            data={residentialCumCommercialList}
            // pagination
            fixedHeader
            fixedHeaderScrollHeight='600px'
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            striped
            actions={
              <>
                {residentialCumCommercialList.length > 0 && (
                  <div className='btn btn-success' onClick={exportToCSV}>
                    <i className='bi bi-download'></i>
                  </div>
                )}

                {userData.role == 'manager' && (
                  <Tooltip title='Add Commercial Consumer'>
                    <Link
                      className='btn btn-primary'
                      // style={{ cursor: 'default' }}
                      to={`/dashboard/consumers/add-consumer`}
                    >
                      <i className='bi bi-plus-lg fa-xl'></i>
                    </Link>
                  </Tooltip>
                )}
              </>
            }
            subHeader
            pagination
            subHeaderComponent={
              <TextField
                id='outlined-basic'
                label='Search by Name'
                variant='outlined'
                className='input w-100'
                size='small'
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign='left'
            noContextMenu={true}
            onSelectedRowsChange={handleSelectedRows}
            clearSelectedRows={toggleCleared}
          />
        </>
      )}

      {/*  */}
    </div>
  )
}

export default ConsumerDashboardUser
