import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../variables'
import Select from 'react-select'
import NavbarSA from '../../components/navbar-sa'

const NewUser = (props) => {
  const notyf = new Notyf()
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('Register User')

  const roleList = [
    { label: 'Manager', value: 'manager' },
    { label: 'Field', value: 'field' },
    { label: 'Monitoring', value: 'monitoring' },
  ]

  const handleInpChange = (event) => {
    if (event.target.name === 'schemeName') {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
        managerEmail: `${event.target.value
          .toLowerCase()
          .replace(/ /g, '_')}.admin@rean.co.in`,
      })
    } else {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
      })
    }
  }

  const addUser = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    console.log(user)
    if (!user.name || !user.email || !user.password || !user.role) {
      notyf.error({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Please fill all the fields!',
        duration: 3000,
        dismissible: true,
      })
      setIsLoading(false)
    } else {
      await Axios.post(server + 'user/new-user', user, {
        headers: { authorization: localStorage.getItem('token') },
      })
        .then(({ data }) => {
          setIsLoading(false)

          if (data.status == 410) {
            navigate('/')
          } else if (data.status === 200) {
            // redirect to dashboard
            notyf.success({
              position: {
                x: 'right',
                y: 'top',
              },
              message: 'User registeration successful!',
              duration: 3000,
              dismissible: true,
            })
            navigate(`/dashboard`)
          } else {
            notyf.error({
              position: {
                x: 'right',
                y: 'top',
              },
              message: data.message,
              duration: 3000,
              dismissible: true,
            })
          }
          // console.log({email,password,data})
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'Internal Server Error!',
            duration: 3000,
            dismissible: true,
          })
        })
    }
  }

  const handleRoleChange = (data) => {
    console.log(data)
    setUser({
      ...user,
      role: data.value,
    })
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
  }, [])

  return (
    <div className='page new-user'>
      <NavbarSA />
      <div className='page-title'>User Registration</div>

      {/* form */}

      <form className='form col-md-4 offset-md-4 col-12'>
        <div className='mb-3'>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='User Name'
              variant='outlined'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='name'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label="User's Email"
              variant='outlined'
              className='input col-12'
              name='email'
              size='small'
              onChange={handleInpChange}
              required
              //   value={user.managerEmail}
            />
          </div>

          <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
            <Select
              styles={{
                control: (base) => ({
                  ...base,

                  minHeight: '52px',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder='Role'
              onChange={handleRoleChange}
              options={roleList}
            />
          </div>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Remarks'
              variant='outlined'
              className='input col-12'
              name='remarks'
              size='small'
              onChange={handleInpChange}
              multiline
              rows={3}
            />
          </div>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Password'
              variant='outlined'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              type='password'
              name='password'
            />
          </div>

          <button
            className='w-100 btn btn-md btn-primary'
            disabled={isLoading}
            onClick={addUser}
          >
            {isLoading ? (
              <span
                className='spinner-border spinner-border-sm me-2'
                role='status'
                aria-hidden='true'
              />
            ) : (
              btnText
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default NewUser
