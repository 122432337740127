import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import DataTable from 'react-data-table-component'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import { ExportToCsv } from 'export-to-csv'
import server from '../../../variables'
import NavbarSA from '../../../components/navbar-sa'
import { Tooltip } from '@mui/material'
import PrintBillModal from './printBillModal'

const BillDashboardUser = (props) => {
  const notyf = new Notyf()
  const navigate = useNavigate()
  const consumerID = useParams().consumer
  const [bill, setBill] = useState([])
  const [userData, setUserData] = useState({})
  const [selectedRowsCSV, setSelectedRowsCSV] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [search, setSearch] = useState('')
  const [billID, setBillID] = useState('')
  const [openPrintBillModal, setOpenPrintBillModal] = useState(false)

  const handleOpenPrintBillModal = (billID) => {
    setOpenPrintBillModal(true)
    setBillID(billID)
  }
  const handleClosePrintBillModal = () => {
    setOpenPrintBillModal(false)
  }

  useEffect(() => {
    document.title = 'Dashboard | RBS'
    fetchBill()
  }, [])

  const fetchBill = async () => {
    await Axios.get(server + 'user/get-all-bill/' + consumerID, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        console.log(data)
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          setBill(data.bill)
          setUserData(data.user)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRowsCSV(selectedRows)
  }

  const exportToCSV = () => {
    if (selectedRowsCSV.length === 0) {
      const data = bill.map((row) => {
        return {
          'Bill ID': row.billID,
          Amount: row.totalBillAmount,
          'Due Date': row.dueDate,
          'Current Reading': row.currentMeterReading,
          'Last Reading': row.lastMeterReading,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Bill List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Bill List',
        headers: [
          'Bill ID',
          'Amount',
          'Due Date',
          'Current Reading',
          'Last Reading',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
    } else {
      const data = selectedRowsCSV.map((row) => {
        return {
          'Bill ID': row.billID,
          Amount: row.totalBillAmount,
          'Due Date': row.dueDate,
          'Current Reading': row.currentMeterReading,
          'Last Reading': row.lastMeterReading,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Bill List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Bill List',
        headers: [
          'Bill ID',
          'Amount',
          'Due Date',

          'Current Reading',
          'Last Reading',
        ],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
      setToggleCleared(!toggleCleared)
      setSelectedRowsCSV([])
    }
  }

  const columns = [
    {
      name: 'Consumer ID',
      selector: (row) => row.consumerID,
      sortable: true,
    },
    {
      name: 'Bill ID',
      selector: (row) => row.billID,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.totalAmountPayable,
      sortable: true,
    },
    {
      name: 'Due Date',
      selector: (row) => row.dueDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Current Reading',
      selector: (row) => row.currentMeterReading,
      sortable: true,
    },
    {
      name: 'Previous Reeading',
      selector: (row) => row.lastMeterReading,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <Tooltip title='View Bill'>
            <div
              className='btn btn-outline-primary btn-sm mx-1'
              onClick={() => {
                navigate(
                  `/dashboard/consumers/${row.consumerID}/bill/${row.billID}`
                )
              }}
            >
              <i className='bi bi-eye-fill' style={{ cursor: 'pointer' }}></i>
            </div>
          </Tooltip>
          <Tooltip title='Print Bill'>
            <div
              className='btn btn-outline-warning btn-sm mx-1'
              onClick={() => {
                handleOpenPrintBillModal(row.billID)
              }}
            >
              <i
                className='bi bi-printer-fill'
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
          </Tooltip>
          {userData.role != 'monitoring' && (
            <Tooltip title='New Payment'>
              <div
                className='btn btn-outline-secondary btn-sm mx-1'
                onClick={() => {
                  navigate(
                    `/dashboard/consumers/${row.consumerID}/bill/${row.billID}/new-Payment`
                  )
                }}
              >
                <i
                  className='bi bi-currency-rupee'
                  style={{ cursor: 'pointer' }}
                ></i>
              </div>
            </Tooltip>
          )}

          <Tooltip title='Payments'>
            <div
              className='btn btn-outline-success btn-sm mx-1'
              onClick={() => {
                navigate(
                  `/dashboard/consumers/${row.consumerID}/bill/${row.billID}/payments`
                )
              }}
            >
              <i className='bi bi-collection' style={{ cursor: 'pointer' }}></i>
            </div>
          </Tooltip>
        </div>
      ),
      center: true,
    },
  ]

  return (
    <div className='page sa-dashboard'>
      <NavbarSA />

      {openPrintBillModal && (
        <PrintBillModal
          open={openPrintBillModal}
          handleClose={handleClosePrintBillModal}
          billID={billID}
        />
      )}
      {/*  */}

      <>
        <DataTable
          title='Bill List'
          columns={columns}
          data={bill}
          // pagination
          fixedHeader
          fixedHeaderScrollHeight='600px'
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          striped
          actions={
            <>
              {bill.length > 0 && (
                <div className='btn btn-success' onClick={exportToCSV}>
                  <i className='bi bi-download'></i>
                </div>
              )}
              {userData.role != 'monitoring' && (
                <Tooltip title='Add Commercial Consumer'>
                  <Link
                    className='btn btn-primary'
                    // style={{ cursor: 'default' }}
                    to={`/dashboard/consumers/${consumerID}/bill/new-bill`}
                  >
                    <i className='bi bi-plus-lg fa-xl'></i>
                  </Link>
                </Tooltip>
              )}
            </>
          }
          subHeader
          pagination
          subHeaderComponent={
            <TextField
              id='outlined-basic'
              label='Search by Name'
              variant='outlined'
              className='input w-100'
              size='small'
              // onChange={(e) => setSearch(e.target.value)}
            />
          }
          subHeaderAlign='left'
          onSelectedRowsChange={handleSelectedRows}
          clearSelectedRows={toggleCleared}
          noContextMenu={true}
        />
      </>

      {/*  */}
    </div>
  )
}

export default BillDashboardUser
