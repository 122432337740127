import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/login'
import SADashboard from './pages/admin/dashboard'
import SchemeDashboard from './pages/admin/scheme/dashboard'
import EditSchemeUser from './pages/admin/scheme/editSchemeUser'
import ConsumerDashboard from './pages/admin/consumer/consumerDashBoard'
import ViewConsumer from './pages/admin/consumer/viewConsumer'
import NewBill from './pages/admin/bill/newBill'
import ViewBill from './pages/admin/bill/viewBill'
import NewScheme from './pages/admin/scheme/newScheme'
import NewConsumer from './pages/admin/consumer/newConsumer'
import NewSchemeUser from './pages/admin/scheme/newSchemeUser'
import BillDashboard from './pages/admin/bill/dashboard'
import NewPayment from './pages/admin/bill/newPayment'
import EditConsumer from './pages/admin/consumer/editConsumer'
import UserDashboard from './pages/user/dashboard'
import ConsumerDashboardUser from './pages/user/consumer/dashboard'
import NewConsumerUser from './pages/user/consumer/newConsumer'
import EditConsumerUser from './pages/user/consumer/editConsumer'
import ViewConsumerUser from './pages/user/consumer/viewConsumer'
import BillDashboardUser from './pages/user/bill/dashboard'
import NewBillUser from './pages/user/bill/newBill'
import ViewBillUser from './pages/user/bill/viewBill'
import NewPaymentUser from './pages/user/bill/newPayment'
import EditUser from './pages/user/editUser'
import NewUser from './pages/user/newUser'
import PaymentRecordUser from './pages/user/bill/paymentRecord'
import PaymentRecord from './pages/admin/bill/paymentRecord'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        {/* Admin Routes */}
        <Route path='/admin/dashboard' element={<SADashboard />} />
        <Route path='/admin/register-scheme' element={<NewScheme />} />
        <Route path='/admin/scheme/:id' element={<SchemeDashboard />} />
        <Route
          path='/admin/scheme/:id/consumers'
          element={<ConsumerDashboard />} //manager's dashboard
        />

        <Route
          path='/admin/scheme/:id/consumers/add-consumer'
          element={<NewConsumer />}
        />

        <Route
          path='/admin/scheme/:id/consumers/:consumer/edit'
          element={<EditConsumer />}
        />

        <Route
          path='/admin/scheme/:id/consumers/:consumer/view'
          element={<ViewConsumer />}
        />

        <Route
          path='/admin/scheme/:id/consumers/:consumer/bill'
          element={<BillDashboard />}
        />

        <Route
          path='/admin/scheme/:id/consumers/:consumer/bill/new-bill'
          element={<NewBill />}
        />
        <Route
          path='/admin/scheme/:id/consumers/:consumer/bill/:bill'
          element={<ViewBill />}
        />

        <Route
          path='/admin/scheme/:id/consumers/:consumer/bill/:bill/new-payment'
          element={<NewPayment />}
        />
        <Route
          path='/admin/scheme/:id/consumers/:consumer/bill/:bill/payments'
          element={<PaymentRecord />}
        />

        <Route path='/admin/scheme/:id/add-user' element={<NewSchemeUser />} />
        <Route path='/admin/scheme/:id/:user' element={<EditSchemeUser />} />
        {/*  */}

        {/* User Routes */}
        <Route path='/dashboard' element={<UserDashboard />} />
        <Route path='/dashboard/:user' element={<EditUser />} />
        <Route path='/dashboard/add-user' element={<NewUser />} />
        <Route
          path='/dashboard/consumers'
          element={<ConsumerDashboardUser />}
        />
        <Route
          path='/dashboard/consumers/add-consumer'
          element={<NewConsumerUser />}
        />
        <Route
          path='/dashboard/consumers/:consumer/edit'
          element={<EditConsumerUser />}
        />
        <Route
          path='/dashboard/consumers/:consumer/view'
          element={<ViewConsumerUser />}
        />
        <Route
          path='/dashboard/consumers/:consumer/bill'
          element={<BillDashboardUser />}
        />
        <Route
          path='/dashboard/consumers/:consumer/bill/new-bill'
          element={<NewBillUser />}
        />
        <Route
          path='/dashboard/consumers/:consumer/bill/:bill'
          element={<ViewBillUser />}
        />
        <Route
          path='/dashboard/consumers/:consumer/bill/:bill/payments'
          element={<PaymentRecordUser />}
        />
        <Route
          path='/dashboard/consumers/:consumer/bill/:bill/new-payment'
          element={<NewPaymentUser />}
        />

        {/*  */}
      </Routes>
    </Router>
  )
}

export default App
