import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import DataTable from 'react-data-table-component'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import { ExportToCsv } from 'export-to-csv'
import server from '../../variables'
import NavbarSA from '../../components/navbar-sa'
import { Tooltip } from '@mui/material'
import DataCard from '../../components/dataCard'
import ChecklistIcon from '@mui/icons-material/Checklist'

const UserDashboard = (props) => {
  const notyf = new Notyf()
  const navigate = useNavigate()
  const [cardData, setCardData] = useState({})
  const [user, setUser] = useState([])
  const [userData, setUserData] = useState({})
  const [search, setSearch] = useState('')
  const [baseUser, setBaseUser] = useState([])
  const [selectedRowsCSV, setSelectedRowsCSV] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)

  useEffect(() => {
    const result = baseUser.filter((user) => {
      return user.name.toLowerCase().match(search.toLowerCase())
    })
    setUser(result)
  }, [search])

  useEffect(() => {
    document.title = 'Dashboard | RBS'
    fetchUserDetails()

    // getCardData()
  }, [])

  const getCardData = async () => {
    await Axios.get(server + 'user/dashboard-card-data', {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        if (data.status == 410) {
          navigate('/', 'hola')
        }
        console.log(data)
        setCardData(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const fetchUserDetails = async () => {
    await Axios.get(server + 'user/get-all-user', {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        // console.log(data)
        if (data.status == 410 || data.user.role == 'field') {
          navigate('/')
        } else if (data.status === 200) {
          setUser(data.userList)
          setUserData(data.user)
          setBaseUser(data.userList)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRowsCSV(selectedRows)
  }

  const exportToCSV = () => {
    if (selectedRowsCSV.length === 0) {
      const data = user.map((row) => {
        return {
          'User ID': row.userID,
          Name: row.name,
          Email: row.email,
          Role: row.role,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'User List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'User List',
        headers: ['User ID', 'Name', 'Email', 'Role'],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
    } else {
      const data = selectedRowsCSV.map((row) => {
        return {
          'User ID': row.userID,
          Name: row.name,
          Email: row.email,
          Role: row.role,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'User List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'User List',
        headers: ['User ID', 'Name', 'Email', 'Role'],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
      setToggleCleared(!toggleCleared)
      setSelectedRowsCSV([])
    }
  }

  const columns = [
    {
      name: 'User ID',
      selector: (row) => row.userID,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => (
        <>
          {userData.role == 'manager' ? (
            <Link className='font-weight-bold' to={`/dashboard/${row.userID}`}>
              {row.name.toUpperCase()}
            </Link>
          ) : (
            row.name.toUpperCase()
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
  ]

  return (
    <div className='page sa-dashboard'>
      <NavbarSA />
      <div className='dashboard-cards row my-5'>
        <div className='col-lg-6 col-md-6'>
          <DataCard
            heading='Users'
            text='Users'
            color='#5559f7'
            // location='/project-management/procurement'
            image={<ChecklistIcon style={{ fontSize: 30 }} />}
          />
        </div>
        <div className='col-lg-6 col-md-6'>
          <DataCard
            heading='Consumers'
            text='Consumers'
            color='#5559f7'
            location={`/dashboard/consumers`}
            image={<ChecklistIcon style={{ fontSize: 30 }} />}
          />
        </div>
      </div>

      {/*  */}
      <DataTable
        title='Scheme Users'
        columns={columns}
        data={user}
        // pagination
        fixedHeader
        fixedHeaderScrollHeight='600px'
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        striped
        actions={
          <>
            {user.length > 0 && (
              <div className='btn btn-success' onClick={exportToCSV}>
                <i className='bi bi-download'></i>
              </div>
            )}
            {userData.role == 'manager' && (
              <Tooltip title='Register User'>
                <Link
                  className='btn btn-primary'
                  // style={{ cursor: 'default' }}
                  to={`/dashboard/add-user`}
                >
                  <i className='bi bi-plus-lg fa-xl'></i>
                </Link>
              </Tooltip>
            )}
          </>
        }
        subHeader
        pagination
        subHeaderComponent={
          <TextField
            id='outlined-basic'
            label='Search by Name'
            variant='outlined'
            className='input w-100'
            size='small'
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign='left'
        onSelectedRowsChange={handleSelectedRows}
        clearSelectedRows={toggleCleared}
        noContextMenu={true}
      />
      {/*  */}
    </div>
  )
}

export default UserDashboard
