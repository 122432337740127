import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../../assets/img/logoFull.png'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../../variables'
import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material'
import NavbarSA from '../../../components/navbar-sa'
import Select from 'react-select'
const NewBill = (props) => {
  const notyf = new Notyf()
  const [bill, setBill] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('New Bill')
  const schemeID = useParams().id
  const [selectedFile, setSelectedFile] = useState({})
  const consumerID = useParams().consumer

  const handleInpChange = (event) => {
    if (event.target.name === 'billName') {
      setBill({
        ...bill,
        [event.target.name]: event.target.value,
        managerEmail: `${event.target.value
          .toLowerCase()
          .replace(/ /g, '_')}.admin@rean.co.in`,
      })
    } else {
      setBill({
        ...bill,
        [event.target.name]: event.target.value,
        consumerID: consumerID,
      })
    }
  }

  const meterStatusOptions = [
    { label: 'Working', value: true },
    { label: 'Not Working', value: false },
  ]

  const handleMeterStatusChange = (data) => {
    console.log(data)
    setBill({
      ...bill,
      working: data.value,
    })
  }
  // const imageChange = (e) => {
  //   const reader = new FileReader()
  //   reader.onload = () => {
  //     setDocument({
  //       ...document,
  //       documentFile: reader.result,
  //     })
  //   }
  //   reader.readAsDataURL(e.target.files[0])
  // }

  const addUser = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    if (
      !bill.dueDate ||
      !bill.currentMeterReading ||
      !bill.fixedCharges ||
      !bill.otherCharges ||
      !bill.totalAmountPayable ||
      !bill.penalty
    ) {
      notyf.error({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Please fill all the fields!',
        duration: 3000,
        dismissible: true,
      })
      setIsLoading(false)
    } else {
      await Axios.post(server + 'admin/new-bill', bill, {
        headers: { authorization: localStorage.getItem('token') },
      })
        .then(({ data }) => {
          setIsLoading(false)

          if (data.status == 410) {
            navigate('/')
          } else if (data.status === 200) {
            // redirect to dashboard
            notyf.success({
              position: {
                x: 'right',
                y: 'top',
              },
              message: 'bill registeration successful!',
              duration: 3000,
              dismissible: true,
            })
            navigate(`/admin/scheme/${schemeID}/consumers/${consumerID}/bill`)
          } else {
            notyf.error({
              position: {
                x: 'right',
                y: 'top',
              },
              message: data.message,
              duration: 3000,
              dismissible: true,
            })
          }
          // console.log({email,password,data})
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'Internal Server Error!',
            duration: 3000,
            dismissible: true,
          })
        })
    }
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
  }, [])

  return (
    <div className='page new-user'>
      <NavbarSA />
      <div className='page-title'>Bill Registration</div>

      {/* form */}

      <form className='form col-md-4 offset-md-4 col-12'>
        <div className='mb-3'>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Due Date'
              variant='outlined'
              type='date'
              InputLabelProps={{ shrink: true }}
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='dueDate'
            />
          </div>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Current Meter Reading'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='currentMeterReading'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Penalty'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='penalty'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Fixed Charges'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='fixedCharges'
            />
          </div>
          <div class='mb-3'>
            <Select
              styles={{
                control: (base) => ({
                  ...base,

                  minHeight: '52px',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder='Meter Status'
              onChange={handleMeterStatusChange}
              options={meterStatusOptions}
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Other Charges'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='otherCharges'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Connection Charges'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='connectionCharges'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Arrear'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='arrearAmount'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Intrest On Arrear'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='intrestOnArrear'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Write off amount'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='writeOffAmount'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Adjust Amount'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='adjustAmount'
            />
          </div>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Consume Amount'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='consumeAmount'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Total Amount'
              variant='outlined'
              type='number'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              required
              name='totalAmountPayable'
            />
          </div>

          <div class='mb-3'>
            {/* <input
              className='form-control '
              type='file'
              name='poDoc'
              placeholder='Quote Doc'
              onChange={(e) => {
                setSelectedFile(e.target.files[0])
              }}
              id='doc'
              //   accept='application/pdf'
              required
            /> */}
          </div>

          <button
            className='w-100 btn btn-md btn-primary'
            disabled={isLoading}
            onClick={addUser}
          >
            {isLoading ? (
              <span
                className='spinner-border spinner-border-sm me-2'
                role='status'
                aria-hidden='true'
              />
            ) : (
              btnText
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default NewBill
