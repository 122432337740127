import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import DataTable from 'react-data-table-component'
import Axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Notyf } from 'notyf'
import { ExportToCsv } from 'export-to-csv'
import 'notyf/notyf.min.css'
import server from '../../variables'
import NavbarSA from '../../components/navbar-sa'
import { Tooltip } from '@mui/material'

const SADashboard = (props) => {
  const notyf = new Notyf()
  const navigate = useNavigate()
  const [selectedRowsCSV, setSelectedRowsCSV] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [schemes, setSchemes] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    document.title = 'Super Admin Dashboard | RBS'
    fetchSchemes()
  }, [])

  const fetchSchemes = async () => {
    await Axios.get(server + 'admin/get-schemes', {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        console.log(data)
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          setSchemes(data.data)
          setSearch(data.data)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const columns = [
    {
      name: 'Scheme ID',
      selector: (row) => row.schemeID,
      sortable: true,
    },
    {
      name: 'Scheme Name',
      selector: (row) => (
        <Link className='font-weight-bold' to={`/admin/scheme/${row.schemeID}`}>
          {row.name.toUpperCase()}
        </Link>
      ),
      sortable: true,
    },
    // {
    //   name: 'Scheme Manager',
    //   selector: (row) => row.user[0].name,
    //   sortable: true,
    // },
  ]

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRowsCSV(selectedRows)
  }

  const exportToCSV = () => {
    if (selectedRowsCSV.length === 0) {
      const data = schemes.map((row) => {
        return {
          'Scheme ID': row.schemeID,
          Name: row.name,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Scheme List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Scheme List',
        headers: ['Scheme ID', 'Name'],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
    } else {
      const data = selectedRowsCSV.map((row) => {
        return {
          'Scheme ID': row.schemeID,
          Name: row.name,
        }
      })
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Scheme List',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Scheme List',
        headers: ['Scheme ID', 'Name'],
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
      notyf.success({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Exporting to CSV...',
        duration: 3000,
        dismissible: true,
      })
      setToggleCleared(!toggleCleared)
      setSelectedRowsCSV([])
    }
  }

  return (
    <div className='page sa-dashboard'>
      <NavbarSA />

      {/*  */}
      <DataTable
        title='Scheme List'
        columns={columns}
        data={schemes}
        // pagination
        fixedHeader
        fixedHeaderScrollHeight='600px'
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        striped
        actions={
          <>
            {schemes.length > 0 && (
              <div className='btn btn-success' onClick={exportToCSV}>
                <i className='bi bi-download'></i>
              </div>
            )}

            <Tooltip title='Register Scheme'>
              <Link
                className='btn btn-primary'
                // style={{ cursor: 'default' }}
                to='/admin/register-scheme'
              >
                <i className='bi bi-plus-lg fa-xl'></i>
              </Link>
            </Tooltip>
          </>
        }
        subHeader
        pagination
        subHeaderComponent={
          <TextField
            id='outlined-basic'
            label='Search by Name'
            variant='outlined'
            className='input w-100'
            size='small'
            // onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign='left'
        onSelectedRowsChange={handleSelectedRows}
        clearSelectedRows={toggleCleared}
        noContextMenu={true}
      />
      {/*  */}
    </div>
  )
}

export default SADashboard
