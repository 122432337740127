import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../../variables'
import { Checkbox, FormControlLabel } from '@mui/material'
import NavbarSA from '../../../components/navbar-sa'
import Select from 'react-select'

const EditConsumerUser = (props) => {
  const notyf = new Notyf()
  const consumerID = useParams().consumer
  const [consumer, setConsumer] = useState({})
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('Save Consumer')
  const [btnText1, setBtnText1] = useState('Delete Consumer')
  const [consumerType, setConsumerType] = useState('None')

  const roleList = [
    { label: 'Manager', value: 'Manager' },
    { label: 'Field', value: 'Field' },
    { label: 'Monitoring', value: 'Monitoring' },
  ]
  const ownerShipStatusList = [
    { label: 'Govt', value: 'Govt' },
    { label: 'NGO', value: 'NGO' },
    { label: 'Private', value: 'Private' },
  ]
  const ConsumerTypeList = [
    { label: 'Commercial Consumer', value: 'Commercial Consumer' },
    { label: 'Residential Consumer', value: 'Residential Consumer' },
    {
      label: 'Residential Cum Commercial Consumer',
      value: 'Residential Cum Commercial Consumer',
    },
  ]
  const bussinessNatureList = [
    { label: 'Hotel', value: 'Hotel' },
    { label: 'Resturant', value: 'Resturant' },
    { label: 'Public', value: 'Public' },
    { label: 'Semi Public', value: 'Semi Public' },
    { label: 'Hostels', value: 'Hostels' },
    { label: 'NGO', value: 'NGO' },
    { label: '12 Bed Hospitals', value: '12 Bed Hospitals' },
    { label: 'Hospitals(12+Bed)', value: 'Hospitals(12+Bed)' },
    { label: 'Industry', value: 'Industry' },
    { label: 'Marraige Garder/Hall', value: 'Marraige Garder/Hall' },
  ]
  const propertyType = [
    { label: 'Single Floor', value: 'Single Floor' },
    { label: 'Duplex', value: 'Duplex' },
    { label: 'Bunglow', value: 'Bunglow' },
    { label: 'Bunglow with Farm', value: 'Bunglow with Farm' },
  ]
  const buildingType = [
    { label: 'Kacha', value: 'Kacha' },
    { label: 'Pakka', value: 'Pakka' },
  ]
  const socialStatus = [
    { label: 'GEN', value: 'GEN' },
    { label: 'OBC', value: 'OBC' },
    { label: 'ST', value: 'ST' },
    { label: 'SC', value: 'SC' },
  ]
  const identityType = [
    { label: 'Aadhar', value: 'Aadhar' },
    { label: 'Passbook', value: 'Passbook' },
    { label: 'Passport', value: 'Passport' },
    { label: 'Driving License', value: 'Driving License' },
    { label: 'Voter ID', value: 'Voter ID' },
  ]
  const identityTypeConsumer = [
    { label: 'Company Name', value: 'Company Name' },
    { label: 'PAN Number', value: 'PAN Number' },
    { label: 'Gst Number', value: 'Gst Number' },
  ]
  const handleInpChange = (event) => {
    setConsumer({
      ...consumer,
      [event.target.name]:
        event.target.valueAsDate ||
        event.target.valueAsNumber ||
        event.target.value,
    })
  }

  const deleteConsumer = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    console.log('delete Clicked')
    await Axios.post(
      server + 'user/delete-consumer',
      { id: consumer._id },
      {
        headers: { authorization: localStorage.getItem('token') },
      }
    )
      .then(({ data }) => {
        setIsLoading(false)
        console.log(data)
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          // redirect to dashboard
          notyf.success({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'User Delete successful!',
            duration: 3000,
            dismissible: true,
          })
          navigate(`/dashboard/consumers`)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
        // console.log({email,password,data})
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const editConsumer = async (e) => {
    console.log('save', consumer)
    setIsLoading(true)
    e.preventDefault()

    await Axios.post(server + 'user/update-consumer/' + consumerID, consumer, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        setIsLoading(false)

        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          // redirect to dashboard
          notyf.success({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'Consumer Update successful!',
            duration: 3000,
            dismissible: true,
          })
          navigate(`/dashboard/consumers`)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
        // console.log({email,password,data})
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const handleBussinessNatureChange = (data) => {
    console.log(data)
    setConsumer({
      ...consumer,
      bussinessNature: data.value,
    })
  }

  const handleConsumerTypeChange = (data) => {
    console.log(data)
    setConsumerType(data.value)
  }

  const handleBuildingChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, buildingType: data.value })
  }

  const getConsumer = async () => {
    await Axios.get(server + 'user/get-consumer/' + consumerID, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        if (data.status == 410 || data.user.role != 'manager') {
          navigate('/')
        } else if (data.status === 200) {
          console.log('edit User', data)
          setConsumerType(data.consumer.consumerType)
          setConsumer(data.consumer)
          setUserData(data.user)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const handlePropertyChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, propertyType: data.value })
  }

  const handleIdenetityChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, identityType: data.value })
  }

  const handleOwnershipStatusChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, ownershipStatus: data.value })
  }

  const handleSocialStatusChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, socialStatus: data.value })
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
    getConsumer()
  }, [])

  return (
    <div className='page new-user'>
      <NavbarSA />

      {/* form */}
      {consumerType == 'Commercial Consumer' ? (
        <>
          <div className='page-title'>Commercial Consumer Registration</div>
          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Name'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  value={consumer.bussinessName}
                  InputLabelProps={{ shrink: true }}
                  name='bussinessName'
                  required
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Bussiness Nature'
                  onChange={handleBussinessNatureChange}
                  options={bussinessNatureList}
                  value={bussinessNatureList.filter(
                    (option) => option.value == consumer.bussinessNature
                  )}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='OwnerShip Status'
                  onChange={handleOwnershipStatusChange}
                  options={ownerShipStatusList}
                  value={ownerShipStatusList.filter(
                    (option) => option.value == consumer.ownershipStatus
                  )}
                />
              </div>

              <FormControlLabel
                control={<Checkbox value={consumer.isEducationInstitute} />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isEducationInstitute: e.target.checked,
                  })
                }}
                label='Is this a edcational institute'
              />

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Identity Type'
                  onChange={handleIdenetityChange}
                  options={identityTypeConsumer}
                  value={identityTypeConsumer.filter(
                    (option) => option.value == consumer.identityType
                  )}
                />
              </div>

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  variant='outlined'
                  className='input col-12'
                  InputLabelProps={{ shrink: true }}
                  size='small'
                  value={consumer.identityNumber}
                  onChange={handleInpChange}
                  required
                  name='identityNumber'
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  variant='outlined'
                  className='input col-12'
                  name='phoneNumber'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.phoneNumber}
                  size='small'
                  type='number'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.address}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                  name='address'
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  value={consumer.latitude}
                  variant='outlined'
                  type='number'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  type='number'
                  label='Longitude'
                  name='longitude'
                  value={consumer.longitude}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.mobileNumber}
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.email}
                  variant='outlined'
                  className='input col-12'
                  name='email'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.meterNumber}
                  label='Meter Number'
                  variant='outlined'
                  className='input col-12'
                  name='meterNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.wardNumber}
                  variant='outlined'
                  className='input col-12'
                  name='wardNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.zoneNumber}
                  variant='outlined'
                  className='input col-12'
                  name='zoneNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  value={
                    consumer.installationDate
                      ? new Date(consumer.installationDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  variant='outlined'
                  className='input col-12'
                  name='installationDate'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  className='input col-12'
                  name='waterRequirement'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='row my-2 m-0'>
                <div className='col-md-6'>
                  <button
                    className='w-100 btn btn-md btn-primary  '
                    disabled={isLoading}
                    onClick={editConsumer}
                  >
                    {isLoading ? (
                      <span
                        className='spinner-border spinner-border-sm me-2 '
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      btnText
                    )}
                  </button>
                </div>
                <div className='col-md-6'>
                  <button
                    className='w-100 btn btn-md btn-danger '
                    disabled={isLoading1}
                    onClick={deleteConsumer}
                  >
                    {isLoading1 ? (
                      <span
                        className='spinner-border spinner-border-sm me-2'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      btnText1
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : consumerType == 'Residential Consumer' ? (
        <>
          <div className='page-title'>Residential Consumer</div>

          {/* form */}

          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.consumerName}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                  name='consumerName'
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Father or Husband Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.fatherHusbandName}
                  name='fatherHusbandName'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Locality Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.localityName}
                  variant='outlined'
                  className='input col-12'
                  name='localityName'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Builiding Type'
                  onChange={handleBuildingChange}
                  options={buildingType}
                  value={buildingType.filter(
                    (option) => option.value == consumer.buildingType
                  )}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Property Type'
                  onChange={handlePropertyChange}
                  options={propertyType}
                  value={propertyType.filter(
                    (option) => option.value == consumer.propertyType
                  )}
                />
              </div>
              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Identity Type'
                  onChange={handleIdenetityChange}
                  options={identityType}
                  value={identityType.filter(
                    (option) => option.value == consumer.identityType
                  )}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  name='identityNumber'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.identityNumber}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Social Status'
                  onChange={handleSocialStatusChange}
                  options={socialStatus}
                  value={socialStatus.filter(
                    (option) => option.value == consumer.socialStatus
                  )}
                />
              </div>
              <FormControlLabel
                control={<Checkbox checked={consumer.isHandicape} />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isHandicape: e.target.checked,
                  })
                }}
                label='Is Handicaped'
              />
              <FormControlLabel
                control={<Checkbox checked={consumer.isBPL} />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isBPL: e.target.checked,
                  })
                }}
                label='Is Bpl'
              />

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.address}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                  name='address'
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  variant='outlined'
                  value={consumer.latitude}
                  type='number'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  type='number'
                  label='Longitude'
                  name='longitude'
                  value={consumer.longitude}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.mobileNumber}
                  variant='outlined'
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.email}
                  variant='outlined'
                  className='input col-12'
                  name='email'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  className='input col-12'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.meterNumber}
                  name='meterNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.wardNumber}
                  variant='outlined'
                  className='input col-12'
                  name='wardNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.zoneNumber}
                  variant='outlined'
                  className='input col-12'
                  name='zoneNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  value={
                    consumer.installationDate
                      ? new Date(consumer.installationDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  variant='outlined'
                  className='input col-12'
                  name='installationDate'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  className='input col-12'
                  name='waterRequirement'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='row my-2 m-0'>
                <div className='col-md-6'>
                  <button
                    className='w-100 btn btn-md btn-primary  '
                    disabled={isLoading}
                    onClick={editConsumer}
                  >
                    {isLoading ? (
                      <span
                        className='spinner-border spinner-border-sm me-2 '
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      btnText
                    )}
                  </button>
                </div>
                <div className='col-md-6'>
                  <button
                    className='w-100 btn btn-md btn-danger '
                    disabled={isLoading1}
                    onClick={deleteConsumer}
                  >
                    {isLoading1 ? (
                      <span
                        className='spinner-border spinner-border-sm me-2'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      btnText1
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : consumerType == 'Residential Cum Commercial Consumer' ? (
        <>
          <div className='page-title'>
            Residential Cum Commercial Consumer Registration
          </div>

          {/* form */}

          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Name'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.bussinessName}
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                  name='bussinessName'
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Bussiness Nature'
                  onChange={handleBussinessNatureChange}
                  options={bussinessNatureList}
                  value={bussinessNatureList.filter(
                    (option) => option.value == consumer.bussinessNature
                  )}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='OwnerShip Status'
                  onChange={handleOwnershipStatusChange}
                  options={ownerShipStatusList}
                  value={ownerShipStatusList.filter(
                    (option) => option.value == consumer.ownershipStatus
                  )}
                />
              </div>

              <FormControlLabel
                control={<Checkbox checked={consumer.isEducationInstitute} />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isEducationInstitute: e.target.checked,
                  })
                }}
                label='Is this a edcational institute'
              />

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  variant='outlined'
                  className='input col-12'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.identityNumber}
                  size='small'
                  onChange={handleInpChange}
                  required
                  name='identityNumber'
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.phoneNumber}
                  variant='outlined'
                  className='input col-12'
                  name='phoneNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  name='consumerName'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Father or Husband Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.fatherHusbandName}
                  name='fatherHusbandName'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Locality Name'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.localityName}
                  name='localityName'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Builiding Type'
                  onChange={handleBuildingChange}
                  options={buildingType}
                  value={buildingType.filter(
                    (option) => option.value == consumer.buildingType
                  )}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Property Type'
                  onChange={handlePropertyChange}
                  options={propertyType}
                  value={propertyType.filter(
                    (option) => option.value == consumer.propertyType
                  )}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Identity Type'
                  onChange={handleIdenetityChange}
                  options={identityType}
                  value={identityType.filter(
                    (option) => option.value == consumer.identityType
                  )}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Social Status'
                  onChange={handleSocialStatusChange}
                  options={socialStatus}
                  value={socialStatus.filter(
                    (option) => option.value == consumer.socialStatus
                  )}
                />
              </div>
              <FormControlLabel
                control={<Checkbox checked={consumer.isHandicape} />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isHandicape: e.target.checked,
                  })
                }}
                label='Is Handicaped'
              />
              <FormControlLabel
                control={<Checkbox checked={consumer.isBPL} />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isBPL: e.target.checked,
                  })
                }}
                label='Is Bpl'
              />

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.address}
                  name='address'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  value={consumer.latitude}
                  variant='outlined'
                  type='number'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  type='number'
                  label='Longitude'
                  name='longitude'
                  value={consumer.longitude}
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.mobileNumber}
                  variant='outlined'
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.email}
                  variant='outlined'
                  className='input col-12'
                  name='email'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.meterNumber}
                  className='input col-12'
                  name='meterNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.wardNumber}
                  variant='outlined'
                  className='input col-12'
                  name='wardNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.zoneNumber}
                  variant='outlined'
                  className='input col-12'
                  name='zoneNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  value={
                    consumer.installationDate
                      ? new Date(consumer.installationDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  variant='outlined'
                  className='input col-12'
                  name='installationDate'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  InputLabelProps={{ shrink: true }}
                  value={consumer.waterRequirement}
                  variant='outlined'
                  className='input col-12'
                  name='waterRequirement'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='row my-2 m-0'>
                <div className='col-md-6'>
                  <button
                    className='w-100 btn btn-md btn-primary  '
                    disabled={isLoading}
                    onClick={editConsumer}
                  >
                    {isLoading ? (
                      <span
                        className='spinner-border spinner-border-sm me-2 '
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      btnText
                    )}
                  </button>
                </div>
                <div className='col-md-6'>
                  <button
                    className='w-100 btn btn-md btn-danger '
                    disabled={isLoading1}
                    onClick={deleteConsumer}
                  >
                    {isLoading1 ? (
                      <span
                        className='spinner-border spinner-border-sm me-2'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      btnText1
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default EditConsumerUser
