import react, { useState } from 'react'

import Logo from '../assets/img/logoLTR.png'
import { Link, useNavigate } from 'react-router-dom'

const NavbarSA = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true)
  const navigate = useNavigate()
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)

  const logout = () => {
    localStorage.removeItem('token')
    navigate('/')
  }

  return (
    <nav className='navbar navbar-expand-lg bg-body-tertiary fixed-top'>
      <div className='container-fluid'>
        <a className='navbar-brand' href='#'>
          <img src={Logo} height={'40'} width='auto' className='mx-2' />
          {/* Billing Management System */}
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label='Toggle navigation'
          onClick={handleNavCollapse}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'></ul>
          <div className='btn btn-outline-danger' onClick={logout}>
            <i class='bi bi-power'></i> Logout
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavbarSA
