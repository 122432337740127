import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../../variables'
import Select from 'react-select'
import NavbarSA from '../../../components/navbar-sa'

const EditSchemeUser = (props) => {
  const notyf = new Notyf()
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('Save User')
  const [btnText1, setBtnText1] = useState('Delete User')
  const [schemeList, setSchemeList] = useState([])
  const schemeID = useParams().id
  const userID = useParams().user

  const roleList = [
    { label: 'Manager', value: 'manager' },
    { label: 'Field', value: 'field' },
    { label: 'Monitoring', value: 'monitoring' },
  ]

  const handleInpChange = (event) => {
    if (event.target.name === 'schemeName') {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
        managerEmail: `${event.target.value
          .toLowerCase()
          .replace(/ /g, '_')}.admin@rean.co.in`,
      })
    } else {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
      })
    }
  }

  const deleteUser = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    console.log('delete Clicked')
    await Axios.post(
      server + 'admin/delete-user',
      { id: user._id },
      {
        headers: { authorization: localStorage.getItem('token') },
      }
    )
      .then(({ data }) => {
        setIsLoading(false)
        console.log(data)
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          // redirect to dashboard
          notyf.success({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'User Delete successful!',
            duration: 3000,
            dismissible: true,
          })
          navigate(`/admin/scheme/${schemeID}`)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
        // console.log({email,password,data})
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const editUser = async (e) => {
    console.log('save', user)
    setIsLoading(true)
    e.preventDefault()

    await Axios.post(server + 'admin/update-user/' + userID, user, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        setIsLoading(false)

        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          // redirect to dashboard
          notyf.success({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'User Update successful!',
            duration: 3000,
            dismissible: true,
          })
          navigate(`/admin/scheme/${schemeID}`)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
        // console.log({email,password,data})
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const handleRoleChange = (data) => {
    console.log(data)
    setUser({
      ...user,
      role: data.value,
    })
  }

  const getUser = async () => {
    await Axios.get(server + 'admin/get-user/' + userID, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          console.log('edit User', data)
          setUser(data.user)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
    getUser()
  }, [])

  return (
    <div className='page new-user'>
      <NavbarSA />
      <div className='page-title'>
        {user.name} ({user.userID})
      </div>

      {/* form */}

      <form className='form col-md-4 offset-md-4 col-12'>
        <div className='mb-3'>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='User Name'
              variant='outlined'
              className='input col-12'
              value={user.name}
              size='small'
              InputLabelProps={{ shrink: true }}
              onChange={handleInpChange}
              name='name'
            />
          </div>

          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label="User's Email"
              variant='outlined'
              className='input col-12'
              InputLabelProps={{ shrink: true }}
              name='email'
              size='small'
              value={user.email}
              onChange={handleInpChange}

              //   value={user.managerEmail}
            />
          </div>

          <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
            <Select
              styles={{
                control: (base) => ({
                  ...base,

                  minHeight: '52px',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder='Role'
              onChange={handleRoleChange}
              options={roleList}
              value={roleList.filter((option) => option.value == user.role)}
            />
          </div>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Remarks'
              variant='outlined'
              className='input col-12'
              name='remarks'
              size='small'
              InputLabelProps={{ shrink: true }}
              value={user.remarks}
              onChange={handleInpChange}
              multiline
              rows={3}
            />
          </div>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='New Password'
              variant='outlined'
              className='input col-12'
              size='small'
              onChange={handleInpChange}
              type='password'
              name='password'
            />
          </div>
          <div className='row my-2 m-0'>
            <div className='col-md-6'>
              <button
                className='w-100 btn btn-md btn-primary '
                disabled={isLoading}
                onClick={editUser}
              >
                {isLoading ? (
                  <span
                    className='spinner-border spinner-border-sm me-2 '
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  btnText
                )}
              </button>
            </div>
            <div className='col-md-6'>
              <button
                className='w-100 btn btn-md btn-danger '
                disabled={isLoading}
                onClick={deleteUser}
              >
                {isLoading ? (
                  <span
                    className='spinner-border spinner-border-sm me-2'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  btnText1
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditSchemeUser
