import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from './../../../variables'
import { Checkbox, FormControlLabel } from '@mui/material'
import NavbarSA from '../../../components/navbar-sa'
import Select from 'react-select'

const NewConsumer = (props) => {
  const notyf = new Notyf()
  const [consumer, setConsumer] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('Create Consumer')
  const [consumerType, setConsumerType] = useState('None')
  const schemeID = useParams().id

  const roleList = [
    { label: 'Manager', value: 'Manager' },
    { label: 'Field', value: 'Field' },
    { label: 'Monitoring', value: 'Monitoring' },
  ]
  const ownerShipStatusList = [
    { label: 'Govt', value: 'Govt' },
    { label: 'NGO', value: 'NGO' },
    { label: 'Private', value: 'Private' },
  ]
  const ConsumerTypeList = [
    { label: 'Commercial Consumer', value: 'Commercial Consumer' },
    { label: 'Residential Consumer', value: 'Residential Consumer' },
    {
      label: 'Residential Cum Commercial Consumer',
      value: 'Residential Cum Commercial Consumer',
    },
  ]
  const bussinessNatureList = [
    { label: 'Hotel', value: 'Hotel' },
    { label: 'Resturant', value: 'Resturant' },
    { label: 'Public', value: 'Public' },
    { label: 'Semi Public', value: 'Semi Public' },
    { label: 'Hostels', value: 'Hostels' },
    { label: 'NGO', value: 'NGO' },
    { label: '12 Bed Hospitals', value: '12 Bed Hospitals' },
    { label: 'Hospitals(12+Bed)', value: 'Hospitals(12+Bed)' },
    { label: 'Industry', value: 'Industry' },
    { label: 'Marraige Garder/Hall', value: 'Marraige Garder/Hall' },
  ]
  const propertyType = [
    { label: 'Single Floor', value: 'Single Floor' },
    { label: 'Duplex', value: 'Duplex' },
    { label: 'Bunglow', value: 'Bunglow' },
    { label: 'Bunglow with Farm', value: 'Bunglow with Farm' },
  ]
  const buildingType = [
    { label: 'Kacha', value: 'Kacha' },
    { label: 'Pakka', value: 'Pakka' },
  ]
  const socialStatus = [
    { label: 'GEN', value: 'GEN' },
    { label: 'OBC', value: 'OBC' },
    { label: 'ST', value: 'ST' },
    { label: 'SC', value: 'SC' },
  ]
  const identityType = [
    { label: 'Aadhar', value: 'Aadhar' },
    { label: 'Passbook', value: 'Passbook' },
    { label: 'Passport', value: 'Passport' },
    { label: 'Driving License', value: 'Driving License' },
    { label: 'Voter ID', value: 'Voter ID' },
  ]
  const identityTypeConsumer = [
    { label: 'Company Name', value: 'Company Name' },
    { label: 'PAN Number', value: 'PAN Number' },
    { label: 'Gst Number', value: 'Gst Number' },
  ]
  const identityTypeAll = [
    { label: 'Company Name', value: 'Company Name' },
    { label: 'PAN Number', value: 'PAN Number' },
    { label: 'Gst Number', value: 'Gst Number' },
    { label: 'Aadhar', value: 'Aadhar' },
    { label: 'Passbook', value: 'Passbook' },
    { label: 'Passport', value: 'Passport' },
    { label: 'Driving License', value: 'Driving License' },
    { label: 'Voter ID', value: 'Voter ID' },
  ]
  const handleInpChange = (event) => {
    setConsumer({
      ...consumer,
      [event.target.name]:
        event.target.valueAsDate ||
        event.target.valueAsNumber ||
        event.target.value,
    })
  }

  const addConsumer = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    if (!consumer.meterNumber || !consumer.latitude || !consumer.longitude) {
      notyf.error({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Please fill all the fields!',
        duration: 3000,
        dismissible: true,
      })
      setIsLoading(false)
    } else {
      await Axios.post(
        server + 'admin/new-consumer',
        {
          consumer,
          consumerType,
          schemeID,
        },
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      )
        .then(({ data }) => {
          setIsLoading(false)

          if (data.status == 410) {
            navigate('/')
          } else if (data.status === 200) {
            // redirect to dashboard
            notyf.success({
              position: {
                x: 'right',
                y: 'top',
              },
              message: 'User registeration successful!',
              duration: 3000,
              dismissible: true,
            })
            navigate(`/admin/scheme/${schemeID}/consumers`)
          } else {
            notyf.error({
              position: {
                x: 'right',
                y: 'top',
              },
              message: data.message,
              duration: 3000,
              dismissible: true,
            })
          }
          // console.log({email,password,data})
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'Internal Server Error!',
            duration: 3000,
            dismissible: true,
          })
        })
    }
  }

  const handleBussinessNatureChange = (data) => {
    console.log(data)
    setConsumer({
      ...consumer,
      bussinessNature: data.value,
    })
  }

  const handleConsumerTypeChange = (data) => {
    console.log(data)
    setConsumerType(data.value)
  }

  const handleBuildingChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, buildingType: data.value })
  }

  const handlePropertyChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, propertyType: data.value })
  }

  const handleIdenetityChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, identityType: data.value })
  }

  const handleOwnershipStatusChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, ownershipStatus: data.value })
  }

  const handleSocialStatusChange = (data) => {
    console.log(data)
    setConsumer({ ...consumer, socialStatus: data.value })
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
  }, [])

  return (
    <div className='page new-user'>
      <NavbarSA />
      <div
        className='offset-3 col-md-6 mb-3 mt-2'
        style={{ textAlign: 'left' }}
      >
        <Select
          styles={{
            control: (base) => ({
              ...base,

              minHeight: '52px',
            }),
            menu: (base) => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          placeholder='Consumer Type'
          onChange={handleConsumerTypeChange}
          options={ConsumerTypeList}
        />
      </div>

      {/* form */}
      {consumerType == 'Commercial Consumer' ? (
        <>
          <div className='page-title'>Commercial Consumer Registration</div>
          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Name'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  name='bussinessName'
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Bussiness Nature'
                  onChange={handleBussinessNatureChange}
                  options={bussinessNatureList}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Ownership Status'
                  onChange={handleOwnershipStatusChange}
                  options={ownerShipStatusList}
                />
              </div>

              <FormControlLabel
                control={<Checkbox />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isEducationInstitute: e.target.checked,
                  })
                }}
                label='Is this a edcational institute'
              />

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Identity Type'
                  onChange={handleIdenetityChange}
                  options={identityTypeConsumer}
                />
              </div>

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  name='identityNumber'
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  variant='outlined'
                  className='input col-12'
                  name='phoneNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  name='address'
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  type='number'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Longitude'
                  name='longitude'
                  variant='outlined'
                  className='input col-12'
                  type='number'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  variant='outlined'
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  className='input col-12'
                  name='email'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  className='input col-12'
                  name='meterNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  variant='outlined'
                  className='input col-12'
                  name='wardNumber'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  variant='outlined'
                  className='input col-12'
                  name='zoneNumber'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  variant='outlined'
                  className='input col-12'
                  name='installationDate'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  variant='outlined'
                  className='input col-12'
                  name='waterRequirement'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <button
                className='w-100 btn btn-md btn-primary'
                disabled={isLoading}
                onClick={addConsumer}
              >
                {isLoading ? (
                  <span
                    className='spinner-border spinner-border-sm me-2'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  btnText
                )}
              </button>
            </div>
          </form>
        </>
      ) : consumerType == 'Residential Consumer' ? (
        <>
          <div className='page-title'>Residential Consumer Registration</div>

          {/* form */}

          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer Name'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  name='consumerName'
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Father or Husband Name'
                  name='fatherHusbandName'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Locality Name'
                  variant='outlined'
                  className='input col-12'
                  name='localityName'
                  size='small'
                  onChange={handleInpChange}
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Builiding Type'
                  onChange={handleBuildingChange}
                  options={buildingType}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Property Type'
                  onChange={handlePropertyChange}
                  options={propertyType}
                />
              </div>
              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Identity Type'
                  onChange={handleIdenetityChange}
                  options={identityType}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  name='identityNumber'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Social Status'
                  onChange={handleSocialStatusChange}
                  options={socialStatus}
                />
              </div>
              <FormControlLabel
                control={<Checkbox />}
                onClick={(e) => {
                  console.log(e.target.checked)
                }}
                label='Is Handicaped'
              />
              <FormControlLabel
                control={<Checkbox />}
                onClick={(e) => {
                  console.log(e.target.checked)
                }}
                label='Is Bpl'
              />

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  name='address'
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  type='number'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Longitude'
                  name='longitude'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  type='number'
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  variant='outlined'
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  className='input col-12'
                  name='email'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  className='input col-12'
                  name='meterNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  variant='outlined'
                  className='input col-12'
                  name='wardNumber'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  variant='outlined'
                  className='input col-12'
                  name='zoneNumber'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  variant='outlined'
                  className='input col-12'
                  name='installationDate'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  variant='outlined'
                  className='input col-12'
                  name='waterRequirement'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <button
                className='w-100 btn btn-md btn-primary'
                disabled={isLoading}
                onClick={addConsumer}
              >
                {isLoading ? (
                  <span
                    className='spinner-border spinner-border-sm me-2'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  btnText
                )}
              </button>
            </div>
          </form>
        </>
      ) : consumerType == 'Residential Cum Commercial Consumer' ? (
        <>
          <div className='page-title'>
            Residential Cum Commercial Consumer Registration
          </div>

          {/* form */}

          <form className='form col-md-4 offset-md-4 col-12'>
            <div className='mb-3'>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Bussiness Name'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  name='bussinessName'
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Bussiness Nature'
                  onChange={handleBussinessNatureChange}
                  options={bussinessNatureList}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Ownership Status'
                  onChange={handleOwnershipStatusChange}
                  options={ownerShipStatusList}
                />
              </div>

              <FormControlLabel
                control={<Checkbox />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isEducationInstitute: e.target.checked,
                  })
                }}
                label='Is this a edcational institute'
              />

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Copy of Resisitration'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  name='resistrationCopy'
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  variant='outlined'
                  className='input col-12'
                  name='phoneNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Consumer Name'
                  variant='outlined'
                  name='consumerName'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Father or Husband Name'
                  name='fatherHusbandName'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                />
              </div>
              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Locality Name'
                  name='localityName'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                />
              </div>

              <div className='mb-3 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Builiding Type'
                  onChange={handleBuildingChange}
                  options={buildingType}
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Property Type'
                  onChange={handlePropertyChange}
                  options={propertyType}
                />
              </div>
              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Identity Type'
                  onChange={handleIdenetityChange}
                  options={identityTypeAll}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Identity Number'
                  name='identityNumber'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div className='mb-2 mt-2' style={{ textAlign: 'left' }}>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,

                      minHeight: '52px',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder='Social Status'
                  onChange={handleSocialStatusChange}
                  options={socialStatus}
                />
              </div>
              <FormControlLabel
                control={<Checkbox />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isHandicape: e.target.checked,
                  })
                }}
                label='Is Handicaped'
              />
              <FormControlLabel
                control={<Checkbox />}
                onClick={(e) => {
                  console.log(e.target.checked)
                  setConsumer({
                    ...consumer,
                    isBPL: e.target.checked,
                  })
                }}
                label='Is Bpl'
              />

              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  name='address'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  label='Latitude'
                  name='latitude'
                  variant='outlined'
                  type='number'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>
              <div class='mb-3 mt-2'>
                <TextField
                  id='outlined-basic'
                  type='number'
                  label='Longitude'
                  name='longitude'
                  variant='outlined'
                  className='input col-12'
                  size='small'
                  onChange={handleInpChange}
                  required
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Mobile Number'
                  variant='outlined'
                  className='input col-12'
                  name='mobileNumber'
                  size='small'
                  type='number'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  className='input col-12'
                  name='email'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Meter Number'
                  variant='outlined'
                  className='input col-12'
                  name='meterNumber'
                  size='small'
                  onChange={handleInpChange}
                  required
                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Ward Number'
                  variant='outlined'
                  className='input col-12'
                  name='wardNumber'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Zone Number'
                  variant='outlined'
                  className='input col-12'
                  name='zoneNumber'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Installation Date'
                  variant='outlined'
                  className='input col-12'
                  name='installationDate'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <div class='mb-3'>
                <TextField
                  id='outlined-basic'
                  label='Water Requirement'
                  variant='outlined'
                  className='input col-12'
                  name='waterRequirement'
                  size='small'
                  onChange={handleInpChange}

                  //   value={user.managerEmail}
                />
              </div>

              <button
                className='w-100 btn btn-md btn-primary'
                disabled={isLoading}
                onClick={addConsumer}
              >
                {isLoading ? (
                  <span
                    className='spinner-border spinner-border-sm me-2'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  btnText
                )}
              </button>
            </div>
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default NewConsumer
