import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import Logo from '../assets/img/logoFull.png'

import server from '../variables'

const Login = (props) => {
  const notyf = new Notyf()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('Login')

  const loginUser = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    console.log({ email, password })

    if (!email || !password) {
      notyf.error({
        position: {
          x: 'right',
          y: 'top',
        },
        message: 'Please fill all the fields!',
        duration: 3000,
        dismissible: true,
      })
      setIsLoading(false)
    } else {
      await Axios.post(server + 'auth/login', {
        email: email,
        password: password,
      })
        .then(({ data }) => {
          setIsLoading(false)
          console.log(data)
          if (data.status == 200) {
            localStorage.setItem('token', data.token)

            if (data.role == 'admin') {
              navigate('/admin/dashboard')
            } else if (data.role == 'field') {
              navigate('/dashboard/consumers')
            } else if (data.role == 'manager') {
              navigate('/dashboard')
            } else if (data.role == 'monitoring') {
              navigate('/dashboard')
            }
          } else if (data.status === 401) {
            notyf.error({
              position: {
                x: 'right',
                y: 'top',
              },
              message: 'Invalid Credentials!',
              duration: 3000,
              dismissible: true,
            })
          } else {
            notyf.error({
              position: {
                x: 'right',
                y: 'top',
              },
              message: data.message,
              duration: 3000,
              dismissible: true,
            })
          }
          // console.log({email,password,data})
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: 'Internal Server Error!',
            duration: 3000,
            dismissible: true,
          })
        })
    }
  }

  useEffect(() => {
    document.title = 'Rean Billing System'
  }, [])

  return (
    <div className='login'>
      <div className='col-md-1 col-5'>
        <img className='logo img-fluid' src={Logo} alt='' />
      </div>

      {/* Form begin */}
      <form className='form col-md-3 col-12'>
        <div className='mb-3'>
          <div class='mb-3'>
            <TextField
              id='outlined-basic'
              label='Email'
              variant='outlined'
              className='input col-12'
              type={'email'}
              size='small'
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div class='mb-3'>
          <TextField
            id='outlined-basic'
            label='Password'
            variant='outlined'
            className='input col-12'
            type={'password'}
            size='small'
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button
          className='w-100 btn btn-md btn-primary'
          type='submit'
          disabled={isLoading}
          onClick={loginUser}
        >
          {isLoading ? (
            <span
              className='spinner-border spinner-border-sm me-2'
              role='status'
              aria-hidden='true'
            />
          ) : (
            btnText
          )}
        </button>
      </form>
      <div
        className='col-md-6 col-12 text-center text-secondary'
        style={{ fontSize: '.75rem', padding: '4px 0' }}
      >
        Version: 2
      </div>
    </div>
  )
}

export default Login
