import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import Select, {
  components,
  ControlProps,
  Props,
  StylesConfig,
} from 'react-select'
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import Axios from 'axios'
import { Notyf } from 'notyf'
import { useReactToPrint } from 'react-to-print'

import 'notyf/notyf.min.css'
import server from '../../../variables'

const PrintBillModal = ({ open, handleClose, billID }) => {
  const notyf = new Notyf()
  const navigate = useNavigate()
  const printRef = React.useRef()
  const [date, setDate] = useState()
  const [bill, setBill] = useState({})
  const [consumer, setConsumer] = useState({})
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  useEffect(() => {
    getBill()
  }, [])

  const getBill = async () => {
    await Axios.get(server + 'admin/get-bill/' + billID, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          console.log('edit User', data)
          setBill(data.bill)
          setConsumer(data.user)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const handleInpChange = (event) => {
    setDate(event.target.valueAsDate)
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
        <DialogTitle>Print Bill</DialogTitle>

        <div
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <button className='btn btn-outline-primary' onClick={handlePrint}>
            <i className='bi bi-printer-fill'></i>
          </button>
        </div>

        <DialogContent>
          <>
            <div
              className='container-fluid payslip'
              ref={printRef}
              style={{
                fontSize: '0.50rem',
                display: 'block',
                padding: '0px',
              }}
            >
              <div
                className='text-center d-flex justify-content-between align-items-center'
                style={{ marginTop: '10px' }}
              >
                <div>
                  <h6 className='payslip-title'>
                    Rean WaterTech Private Limited
                  </h6>
                  {/* <h6 className='payslip-title'>Bill</h6> */}
                </div>

                {/* <img
                  src={Logo1}
                  style={{
                    width: '10%',
                    height: 'auto',
                    display: 'block',
                  }}
                  className='logo mb-4 '
                  alt='logo'
                /> */}
              </div>

              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col' colSpan={2} className='text-center'>
                        Water Bill
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          Division:
                        </td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          {bill.otherDetails
                            ? bill.otherDetails.schemeName
                              ? bill.otherDetails.schemeName
                              : 'Na'
                            : 'Na'}
                        </td>
                      </td>
                      <td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          Device ID
                        </td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          {bill.readingCollectedBy
                            ? bill.readingCollectedBy
                            : 'Na'}
                        </td>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          Bill No:
                        </td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          {bill.billID ? bill.billID : 'Na'}
                        </td>
                      </td>
                      <td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          Reading Date:
                        </td>
                        <td
                          style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontWeight: 700,
                          }}
                        >
                          {bill.readingDate
                            ? bill.readingDate.split('T')[0]
                            : 'Na'}
                        </td>
                      </td>
                    </tr>

                    <tr>
                      <th scope='col' colSpan={2} className='text-center'>
                        Consumer Details
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Consumer Name
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer ? consumer.consumerName : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Consumer ID
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          textWrap: 'pretty',
                          fontWeight: 700,
                        }}
                      >
                        {bill.consumerID}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Ward
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer ? consumer.wardNumber : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Consumer Address
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer.address ? consumer.address : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Mobile Number
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer.mobileNumber ? consumer.mobileNumber : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <th scope='col' colSpan={2} className='text-center'>
                        Bill Details
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Consumer Type
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer.consumerType ? consumer.consumerType : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Bill Period
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer.readingDate ? consumer.readingDate : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Due Date
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {bill.dueDate ? bill.dueDate.split('T')[0] : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <th scope='col' colSpan={2} className='text-center'>
                        Meter Details
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Meter Number
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer.meterNumber ? consumer.meterNumber : 'Na'}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Meter Reader
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {bill.otherDetails
                          ? bill.otherDetails.readingCollectedBy
                            ? bill.otherDetails.readingCollectedBy
                            : 'Na'
                          : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Meter Status
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {consumer.meterStatus
                          ? consumer.meterStatus
                          : 'Working'}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Previous Reading Date
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {/* {bill ? bill.lastMeterReading : 'Na'} */}
                        {consumer
                          ? consumer.otherDetails
                            ? consumer.otherDetails.lastReadingDate.split(
                                'T'
                              )[0]
                            : 'Na'
                          : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Present Meter Reading
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {bill.currentMeterReading
                          ? bill.currentMeterReading
                          : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Previous Meter Reading
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {bill.lastMeterReading ? bill.lastMeterReading : 'Na'}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Consumption
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {bill.totalConsumption ? bill.totalConsumption : 'Na'}
                      </td>
                    </tr>
                    <tr>
                      <th scope='col' colSpan={2} className='text-center'>
                        Bill Description
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Consumer AMT
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.totalAmountPayable
                          ? bill.totalAmountPayable
                          : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Fixed Charges
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.fixedCharges ? bill.fixedCharges : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Fine Charges
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.penalty ? bill.penalty : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Other Charges
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.otherCharges ? bill.otherCharges : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Meter Connection Charges
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.connectionCharges ? bill.connectionCharges : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Write Off Amount
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.writeOffAmount ? bill.writeOffAmount : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Adjust Amount
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.adjustAmount ? bill.adjustAmount : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Bill Amount
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.totalBillAmount ? bill.totalBillAmount : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Arrears Amount
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.arrearAmount ? bill.arrearAmount : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Inst On Arrear
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.intrestOnArrear ? bill.intrestOnArrear : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Amount Paid
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>0
                      </td>
                    </tr>

                    <tr>
                      <th scope='row'>Balance Amount</th>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>
                        {bill.totalAmountPayable
                          ? bill.totalAmountPayable
                          : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        Amount After Due Date
                      </td>
                      <td
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        <i class='bi bi-currency-rupee'></i>--
                      </td>
                    </tr>
                    <tr>
                      <th scope='col' colSpan={2} className='text-center'>
                        GPS Coordinates:{' '}
                        {consumer.latitude ? consumer.latitude : '--'},{' '}
                        {consumer.longitude ? consumer.longitude : '--'}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PrintBillModal
