import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../../assets/img/logoFull.png'
import TextField from '@mui/material/TextField'
import Axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import server from '../../../variables'

import NavbarSA from '../../../components/navbar-sa'
import DataTable from 'react-data-table-component'

const PaymentRecord = (props) => {
  const notyf = new Notyf()
  const [bill, setBill] = useState({})
  const [consumer, setConsumer] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRowsCSV, setSelectedRowsCSV] = useState([])
  const navigate = useNavigate()
  const [btnText, setBtnText] = useState('New Bill')
  const [payments, setPayments] = useState([])
  const consumerID = useParams().consumer
  const billID = useParams().bill
  const [toggleCleared, setToggleCleared] = useState(false)

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRowsCSV(selectedRows)
  }

  const getBill = async () => {
    await Axios.get(server + 'admin/get-bill/' + billID, {
      headers: { authorization: localStorage.getItem('token') },
    })
      .then(({ data }) => {
        if (data.status == 410) {
          navigate('/')
        } else if (data.status === 200) {
          console.log('edit User', data)
          setBill(data.bill)
          setConsumer(data.user)
          setPayments(data.bill.payments)
        } else {
          notyf.error({
            position: {
              x: 'right',
              y: 'top',
            },
            message: data.message,
            duration: 3000,
            dismissible: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        notyf.error({
          position: {
            x: 'right',
            y: 'top',
          },
          message: 'Internal Server Error!',
          duration: 3000,
          dismissible: true,
        })
      })
  }

  const columns = [
    {
      name: 'Amount Paid',
      selector: (row) => row.amountPaid,
      sortable: true,
    },
    {
      name: 'Amount Mode',
      selector: (row) => row.paymentMode,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.paymentID,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => (
        <>{row.date ? new Date(row.date).toISOString().split('T')[0] : ''}</>
      ),
      sortable: true,
    },
  ]

  useEffect(() => {
    document.title = 'Rean Billing System'
    getBill()
  }, [])

  return (
    <>
      {bill && (
        <div className='page new-user'>
          <NavbarSA />
          <div className='page-title'>Payments ({bill.billID})</div>

          {/* form */}
          <DataTable
            title='Payment List'
            columns={columns}
            data={payments}
            // pagination
            fixedHeader
            fixedHeaderScrollHeight='600px'
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            pagination
            subHeaderComponent={
              <TextField
                id='outlined-basic'
                label='Search by Name'
                variant='outlined'
                className='input w-100'
                size='small'
                // onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign='left'
            onSelectedRowsChange={handleSelectedRows}
            clearSelectedRows={toggleCleared}
            noContextMenu={true}
          />
        </div>
      )}
    </>
  )
}

export default PaymentRecord
