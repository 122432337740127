import React from 'react'
import { useNavigate } from 'react-router-dom'

const DataCard = (props) => {
  const navigate = useNavigate()

  const goTo = (props) => {
    let location = props.location ? props.location : '#'
    navigate(`${location}`)
  }

  return (
    <div
      className='data-card col-lg-12 mb-4 mb-lg-0'
      onClick={() => {
        goTo(props)
      }}
    >
      <div className='row'>
        <div className='col-10 left'>
          <h6 className='left-heading'>{props.heading}</h6>
          <p className='left-text'>{props.text}</p>
        </div>
        <div className='col-2 right'>
          <div className='right-image' style={{ backgroundColor: props.color }}>
            {props.image}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataCard
